import Typography from '@mui/material/Typography';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import {inTransistAccessActions} from '@app/screens/inventory-management/pages/in-transit-access/redux';
import {useRef, useState} from 'react';
import IntransitAccessUserNavSearch from '@app/components/intransit-access-user-nav-search';
import { InventoryToogle } from '@app/screens/inventory-management/pages/intransit-challan/toogleButton';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '20px',
  },
  antSwitch: {
    marginTop: '4px !important',
  },
};

export const InTransitAccessNavBar = () => {
  const [type, setType] = useState<string>('');
  const dispatch = useDispatch();
  const handleToggleChange = (value?: any, index?: number) => {
    setType(value);
    dispatch(inTransistAccessActions.setInTransistAccessType(value));
    dispatch(inTransistAccessActions.SetPageNumber(0));
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}
          marginLeft={'16px'}>
          In-Transit Access
        </Typography>
        <Box sx={{padding: 0}}>
          <InventoryToogle
            containerStyle={{
              width: '262px',
              backgroundColor: '#fff',
              borderRadius: 20,
            }}
            itemStyle={{justifyContent: 'center', top: 0, bottom: 0}}
            name="group-1"
            callback={handleToggleChange}
            controlRef={useRef()}
            segments={[
              {
                label: 'Division',
                value: 'Division',
                ref: useRef(),
              },
              {
                label: 'State',
                value: 'State',
                ref: useRef(),
              },
              {
                label: 'User',
                value: 'User',
                ref: useRef(),
              },
            ]}
          />
        </Box>
      </Box>
      {type === 'User' && <IntransitAccessUserNavSearch />}
    </Box>
  );
};

import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { routeSelector } from '@app/router/redux';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SearchBar } from '@app/components/widgets/searchBar';
import { seachFilterOptions, DataCorrectionOption } from '@app/common/constants'
import Switch from '@mui/material/Switch';
import { fetchPartyDetailBySpidCreator, positionManagementSelector, positionManagementStateActions } from '@app/screens/module-specific-utilities/pages/position-management/redux'
import { dataCorrectionStateActions, fetchDataCorrectionListCreator } from '@app/screens/master-data-management/pages/data-correction/redux'

const correctionTypeOption = ['Bulk', 'Single']

export const DataCorrectionNavBar = () => {
  const dispatch = useDispatch();
  const [searchOptionSelected, setSearchOptionSelected] = useState(2)
  const [searchError, setSearchError] = useState(false);
  const [search, setSearch] = useState('');
  const [errorMsg, setErrorMsg] = useState('')
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [suggestionList, setSuggestionList] = useState([])
  const [dataCorretionType, setDataCorretionType] = useState(1)
  const [correctionType, setCorrectionType] = useState({
    checked: true,
    type: correctionTypeOption,
  });
  const classes = useStyles(correctionType);
  const partyDetails = useSelector(positionManagementSelector.getPartyDetails())
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
    shallowEqual,
  );

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      if (search?.length >= 3) {
        setSearchError(false);
        const keySelected = getOptionKey(searchOptionSelected)
        dispatch(fetchPartyDetailBySpidCreator({ payload: { [keySelected as string]: search, isSelfScreen: true } }))
      } else {
        setSearchError(true);
        setErrorMsg("Please Enter Minimum 3 Characters");
      }
    }
  };

  useEffect(() => {
    dispatch(positionManagementStateActions.setPartiesDetails(null))
  }, [])

  useEffect(() => {
    if (partyDetails) {
      if (partyDetails?.details?.length > 1) {
        setShowSuggestion(true);
        setSuggestionList(partyDetails?.details);
        return;
      } else if (partyDetails?.details?.staffPositionId) {
        const { empid, empName, staffPositionId, wsfaPositionCode } = partyDetails?.details;
        let searchPlaceholder = empid ? `${empid} | ` : '';
        searchPlaceholder = empName ? `${searchPlaceholder} ${empName} |` : searchPlaceholder;
        searchPlaceholder = staffPositionId ? `${searchPlaceholder} ${staffPositionId} |` : searchPlaceholder;
        searchPlaceholder = wsfaPositionCode ? `${searchPlaceholder} ${wsfaPositionCode}` : searchPlaceholder;
        setSearch(searchPlaceholder);
        dispatch(fetchDataCorrectionListCreator({ ...partyDetails?.details, masterType: 0 }))
      } else if (partyDetails?.details?.length === 0) {
        setSearchError(true)
        setErrorMsg(`${getOptionName(searchOptionSelected)} not found`)
      }
    }
  }, [partyDetails])

  const handleCorrectionType = (event: any) => {
    setCorrectionType({
      ...correctionType,
      [event.target.name]: event.target.checked,
    });
    dispatch(dataCorrectionStateActions.setCorrectionType(event.target.checked))
    dispatch(dataCorrectionStateActions.setUpdatedStartDates([]))
  };

  const handleSuggestionSelected = (value: any) => {
    setShowSuggestion(false);
    dispatch(positionManagementStateActions.setPartiesDetails({ details: value }))
  }

  const getOptionName = (value: any) => {
    const selectedOption = seachFilterOptions.find((option: any) => {
      return option?.value === value
    })
    return selectedOption?.name
  }

  const getOptionKey = (value: any) => {
    const selectedOption = seachFilterOptions.find((option: any) => {
      return option?.value === value
    })
    return selectedOption?.key
  }

  const handleSearchTextChange = (value: any) => {
    const regex = getOptionKey(searchOptionSelected) === 'employeeName' ? /^[a-zA-Z ]*$/ : /^[0-9\b]+$/
    if (value === '' || regex.test(value)) {
      setSearch(value)
    }
  };


  const handleSearchOptionChange = (val: any) => {
    setSearchError(false)
    setSearchOptionSelected(val)
    setSearch('')
    dispatch(positionManagementStateActions.setPartiesDetails(null))
  }


  const searchFilterOption = () => {
    return (
      <div className={classes.searchRadioBtns}>
        <RadioGroup
          name={`radio-btns`}
          value={searchOptionSelected}
          row
        >
          {seachFilterOptions?.map((option) =>
            <FormControlLabel
              value={option.value}
              control={<Radio className={classes.optionRadio} />}
              label={option.name}
              onChange={() => handleSearchOptionChange(option?.value)}
              data-testid={`${option.key}-radio`}
              key={option.key}
              className={classes.formLabel}
            />
          )}
        </RadioGroup>
      </div>
    )
  }

  const handleClearClick = () => {
    setSearch('')
    setSearchError(false);
    setErrorMsg('');
    setShowSuggestion(false)
    dispatch(positionManagementStateActions.setPartiesDetails(null))
  }

  const handleChange = (event: any) => {
    setDataCorretionType(event.target.value);
  };

  return (
    <Box
      className={classes.container}>
      <Box className={classes.flexSpaceBetween}>
        <Typography variant="h6" noWrap component="div">
          {navbarComponentHeading}
        </Typography>
      </Box>

      <Box className={classes.searchContainer}>
        <FormControl className={classes.typeDropdown} sx={{ mt: 3 }}>
          <Select
            id="correction-type"
            value={dataCorretionType}
            data-testid={"correction-type"}
            onChange={handleChange}
            className={classes.dropdown}
            inputProps={{ 'aria-label': 'Without label' }}
            renderValue={(selected) => {
              if (selected === 0) {
                return 'Select';
              } else {
                return DataCorrectionOption?.find(val => selected === val.id)?.name
              }
            }}
          >
            <MenuItem disabled value="0">
              <em>Select Type</em>
            </MenuItem>
            {DataCorrectionOption?.map((type: any) =>
              <MenuItem value={type?.id} key={`correction-type-${type?.id}`} data-testid={type?.name}>{type?.name}</MenuItem>
            )}
          </Select>
        </FormControl>
        <div className={classes.searchBar}>
          <SearchBar
            searchText={search}
            handleChange={(e) => handleSearchTextChange(e)}
            handleSearch={(e) => handleSearchPress(e)}
            showError={searchError}
            placeHolder={`Search By ${getOptionName(searchOptionSelected)} `}
            errorMessage={errorMsg}
            disabled={partyDetails?.details && Object.keys(partyDetails?.details)?.length > 0 ? true : false}
            handleClear={() => handleClearClick()}
            showSuggestion={showSuggestion}
            suggestionList={suggestionList}
            suggestionSelected={(e: any) => handleSuggestionSelected(e)}
          />
          <div className={classes.optionContainer}>
            {searchFilterOption()}
            <FormControl sx={{ mt: 1.2, width: 140, alignSelf: 'end' }}>
              <Switch
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                checked={correctionType.checked}
                onChange={handleCorrectionType}
                name="checked"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                data-testid={'switchOption'}
              />
            </FormControl>
          </div>

        </div>

      </Box>
      <div>

      </div>
    </Box>
  );
};

import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {
  approveExpenseStatusCreator,
  fetchDailyExpenseApprovalsDataCreator,
  fetchMiscExpenseApprovalsDataCreator,
  dailyExpenseExceptionSummary,
  footerDataCreator,
  fetchExpenseApprovalsDataCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {useEffect} from 'react';
import {SEARCH_OBJECT} from '../../module-specific-utilities.types';
import {getPreviousYearMonths} from '../expense-reports/TA-summary/TASummaryReportHeader';
import {styles} from './styles/customStyle';
import {strings} from '@app/common/strings';

export const util: any = {
  handleGoClick: null,
  handleChange: null,
  getMonth: null,
};

export const ApproveExpenseTabViewSelectionHeader = () => {
  const dispatch = useDispatch();
  const selectedManagerData: SEARCH_OBJECT = useSelector(
    approveExpenseStateSelector?.getSelectedManagerData(),
  );
  const selectedStaffData: any = useSelector(
    approveExpenseStateSelector?.getSelectedStaffData(),
  );
  const selectedExpenseApprovalsData = useSelector(
    approveExpenseStateSelector?.getSelectedExpenseApprovalsData(),
  );
  const statusData = useSelector(approveExpenseStateSelector?.getStatusData());
  const monthData = useSelector(approveExpenseStateSelector?.getMonthData());
  const filteredData = useSelector(
    approveExpenseStateSelector?.getFilterData(),
  );
  const SelectedExpenseApprovalsUser = useSelector(
    approveExpenseStateSelector?.getSelectedExpenseApprovalsUser(),
  );
  const monthYearData = [{monthDesc: 'Select All', year: 0, month: 0}].concat(
    getPreviousYearMonths(),
  );
  const isApprovedsatffId = useSelector(
    approveExpenseStateSelector?.getApprovedStaffDetails(),
  );
  const handleChange = (event: any) => {
    if (event?.target?.value) {
      const daily = {
        sortedField: '',
        sortType: '',
      };
      const misc = {
        field: '',
        sortDirection: '',
      };
      dispatch(approveExpenseStateActions.setMiscSort(daily));
      dispatch(approveExpenseStateActions.setDailySort(misc));
      setTimeout(() => {
        dispatch(approveExpenseStateActions.resetDailyTableWidth());
        dispatch(approveExpenseStateActions.resetMiscTableWidth());
      }, 0);
      dispatch(approveExpenseStateActions.setDailyExpenseApprovalsData(null));
      dispatch(approveExpenseStateActions.setMiscExpenseApprovalsData(null));
      dispatch(approveExpenseStateActions.setFooterDatas({}));
      dispatch(
        approveExpenseStateActions.setSelectedExpenseApprovalsUser(
          event?.target?.value,
        ),
      );

      const requestBody = {
        month: event?.target?.value?.expenseMonth,
        year: event?.target?.value?.expenseYear,
        staffPositionId: event?.target?.value?.staffPositionId,
        userId: event?.target?.value?.staffUserId,
      };
      dispatch(dailyExpenseExceptionSummary(requestBody));
      dispatch(approveExpenseStatusCreator(requestBody));
      dispatch(fetchDailyExpenseApprovalsDataCreator(requestBody));
      dispatch(fetchMiscExpenseApprovalsDataCreator(requestBody));
      dispatch(footerDataCreator(null));
      dispatch(approveExpenseStateActions.setConfirmExpenseApprovalsReset());
      dispatch(approveExpenseStateActions.setRejectExpenseApprovalsReset());
      dispatch(approveExpenseStateActions.setRejectExpenseModalCheckbox(false));
      dispatch(
        approveExpenseStateActions.setApproveExpenseModalCheckbox(false),
      );
    }
  };

  const approveExpenseElsePart = () => {
    dispatch(
      approveExpenseStateActions.setApproveExpenseScreen({
        screen: 1,
        backBtn: false,
      }),
    );
    const statusVal = () => {
      return statusData
        ?.filter((statusData: any) =>
          filteredData?.statusId?.includes(statusData?.displayName),
        )
        ?.map((status: any) => status?.id);
    };
    const dropdownValues = {
      divisionIds: filteredData?.divisionId,
      months: monthYearData
        ?.map((ele: any) => {
          if (filteredData?.monthYearData?.includes(ele?.month))
            return {
              month: ele?.month,
              year: ele?.year,
            };
        })
        ?.filter((element: any) => Boolean(element) && element?.month !== 0),
      year: filteredData?.year,
      statusIds: statusVal(),
      stateIds: filteredData?.stateId ? filteredData?.stateId : [],
      userIds:
        !selectedManagerData && selectedStaffData?.userId
          ? [selectedStaffData?.userId]
          : selectedStaffData ?? [],
      managerStaffPositionId: selectedManagerData
        ? selectedManagerData?.staffPositionId
        : 0,
    };
    setTimeout(() => {
      dispatch(fetchExpenseApprovalsDataCreator(dropdownValues));
    }, 100);

    dispatch(approveExpenseStateActions.setSelectedExpenseApprovalsUser({}));
    dispatch(approveExpenseStateActions.setSelectedExpenseApprovalsData([]));
    dispatch(approveExpenseStateActions.setApprovedStaffDetails(''));
  };
  const initalCall = (i: any) => {
    if (selectedExpenseApprovalsData[i]) {
      const requestBody = {
        month: selectedExpenseApprovalsData[i]?.expenseMonth,
        year: selectedExpenseApprovalsData[i]?.expenseYear,
        staffPositionId: selectedExpenseApprovalsData[i]?.staffPositionId,
        userId: selectedExpenseApprovalsData[i]?.staffUserId,
      };
      dispatch(fetchDailyExpenseApprovalsDataCreator(requestBody));
      dispatch(fetchMiscExpenseApprovalsDataCreator(requestBody));
      dispatch(
        approveExpenseStateActions.setSelectedExpenseApprovalsUser(
          selectedExpenseApprovalsData[i],
        ),
      );
      dispatch(footerDataCreator(null));
      dispatch(approveExpenseStatusCreator(requestBody));
      dispatch(
        approveExpenseStateActions.setMiscExpenseApprovalsRequest(requestBody),
      );
      dispatch(dailyExpenseExceptionSummary(requestBody));
    }
  };
  useEffect(() => {
    let i = 0;
    if (isApprovedsatffId) {
      const index = selectedExpenseApprovalsData?.findIndex(
        (element: {staffPositionId: any}) =>
          element?.staffPositionId == isApprovedsatffId,
      );
      if (index + 1 < selectedExpenseApprovalsData?.length) {
        i = index + 1;
      } else {
        approveExpenseElsePart();
      }
    }
    initalCall(i);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApprovedsatffId]);

  const getMonth = (monthInNum: number) => {
    return (monthData || [])
      ?.find((month: any) => month?.key === monthInNum)
      ?.value?.substring(0, 3);
  };

  util.handleChange = handleChange;
  util.getMonth = getMonth;

  return (
    <Grid container spacing={0.5} sx={styles.tabViewContainer}>
      <Grid item xs={4}>
        <Box sx={{minWidth: 300}}>
          <FormControl size="small" fullWidth>
            <InputLabel id="select-staff-dropdown" sx={styles.font12}>
              {strings.ModuleSpecificUtilitie.approveExpense.selectStaff}
            </InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-State',
              }}
              id="select-staff-dropdown"
              value={SelectedExpenseApprovalsUser || ''}
              onChange={handleChange}
              label="Select Staff"
              sx={styles.font11}>
              {selectedExpenseApprovalsData?.map((data: any) => (
                <MenuItem value={data} key={data?.staffPositionId}>
                  {data?.staffName}-{data?.staffUserId}({data?.designationName}-
                  {data?.hqName}-{getMonth(data?.expenseMonth)}-
                  {data?.expenseYear})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};

import {createSelector} from '@reduxjs/toolkit';

const navData = (state: any) => state.leaveManagment.nav;

const getNavTitle = createSelector([navData], nav => nav.title);

const initLoading = (state: any) => state.leaveManagment.loading;
const getLoading = createSelector([initLoading], _loading => _loading);

const allReporters = (state: any) => state.leaveManagment.allReporters;
const allReportersSelector = createSelector([allReporters], data => data);

const balanceLeave = (state: any) => state.leaveManagment.balanceLeave;
const balanceLeaveSelector = createSelector([balanceLeave], data => data);

const leaveHistory = (state: any) => state.leaveManagment.leaveHistory;
const leaveHistorySelector = createSelector([leaveHistory], data => data);

const userRole = (state: any) => state.leaveManagment.userRole;
const userRoleSelector = createSelector([userRole], data => data);
const leaveFilterDataHistory = (state: any) =>
  state.leaveManagment.leaveFilterData;
const leaveFilterDataSelector = createSelector(
  [leaveFilterDataHistory],
  data => data,
);

const applyedFilterDataHistory = (state: any) =>
  state.leaveManagment.applyedFilterData;
const applyedFilterDataSelector = createSelector(
  [applyedFilterDataHistory],
  data => data,
);

const totalRowCountDataHistory = (state: any) =>
  state.leaveManagment.totalRowCount;
const totalRowCountDataSelector = createSelector(
  [totalRowCountDataHistory],
  data => data,
);

const allVoidReasons = (state: any) => state.leaveManagment.voidLeaveReasons;
const allVoidReasonsSelector = createSelector([allVoidReasons], data => data);

export const leaveSelector = {
  getNavTitle: () => getNavTitle,
  getLoading: () => getLoading,
  getReportersSelector: () => allReportersSelector,
  getBalanceLeaveSelector: () => balanceLeaveSelector,
  getLeaveHistorySelector: () => leaveHistorySelector,
  getUserRoleSelector: () => userRoleSelector,
  getLeaveFilterDataSelector: () => leaveFilterDataSelector,
  getApplyedFilterDataSelector: () => applyedFilterDataSelector,
  getTotalRowCountDataSelector: () => totalRowCountDataSelector,
  allVoidReasonsSelector: () => allVoidReasonsSelector,
};

import {createSelector} from '@reduxjs/toolkit';

const getDcrTransferPositionState = (state: any) =>
  state.dcrTransferPromotionMaster;

const getEmployeesList = createSelector([getDcrTransferPositionState], data => {
  return data?.employeeList;
});

const positionTransferRequestSelector = createSelector(
  [getDcrTransferPositionState],
  data => {
    return data?.positionTransferRequestList;
  },
);
const getPositionTransferUserDetailsBySapCodeSelector = createSelector(
  [getDcrTransferPositionState],
  data => {
    return data?.positionTransferUserDetails;
  },
);
const getPositionTransferRequestsTotalCount = createSelector(
  [getDcrTransferPositionState],
  data => {
    return data?.positionTransferRequestList.totalCount;
  },
);
const getPositionTransferStatusSelector = createSelector(
  [getDcrTransferPositionState],
  data => {
    return data?.positionTransferStatus;
  },
);
const getPositionTransferRequestSearchTextSelector = createSelector(
  [getDcrTransferPositionState],
  data => data?.searchText,
);
const getPositionTransferRequestGridOptionSelector = createSelector(
  [getDcrTransferPositionState],
  data => data?.gridOptions,
);
const getOriginalPositionRevertResponseSelector = createSelector(
  [getDcrTransferPositionState],
  data => data?.originalPositionRevertResponse,
);
const getPreviousPositionTransferResponseSelector = createSelector(
  [getDcrTransferPositionState],
  data => data?.transferToPrevPositionResponse,
);
export const dcrTransferPromotionSelector = {
  getEmployeesList: () => getEmployeesList,
  getPositionTransferList: () => positionTransferRequestSelector,
  getPositionTransferUserDetailsBySapCodeSelector: () =>
    getPositionTransferUserDetailsBySapCodeSelector,
  getPositionTransferStatusSelector: () => getPositionTransferStatusSelector,
  getPositionTransferRequestGridOptionSelector: () =>
    getPositionTransferRequestGridOptionSelector,
  getPositionTransferRequestSearchTextSelector: () =>
    getPositionTransferRequestSearchTextSelector,
  getOriginalPositionRevertResponseSelector: () =>
    getOriginalPositionRevertResponseSelector,
  getPreviousPositionTransferResponseSelector: () =>
    getPreviousPositionTransferResponseSelector,
};

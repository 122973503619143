import {useEffect, useState} from 'react';
import {Button, Container} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {useStyles} from '@app/screens/Styles/style';
import {routeStateActions} from '@app/router/redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {strings} from '@app/common/strings';
import {ActiveInActiveToggle} from '@app/components/navbar/module-specific-utilities/status-manager';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import {expenseReportsActions} from '@app/screens/module-specific-utilities/pages/expense-reports/redux';
import ExpenseStatusReportHeader from './ExpenseStatusReportHeader';
import ExpenseStatusReport from './ExpenseStatusReport';

const ExpenseStatusReportContent: React.FC<{setReportBack: any}> = ({
  setReportBack,
}) => {
  const classes = useStyles();
  const [report, setReport] = useState(false);
  const dispatch = useDispatch();
  const isActive = useSelector(
    expenseReportsStateSelector.getIsActiveTaSummary(),
  );

  const toggleHandler = (event: any) => {
    dispatch(
      expenseReportsActions?.setIsActiveTaSummary(event?.target?.checked),
    );
    setReport(false);
  };

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div>
          <div className={classes.expenseReportsNavStyleTitle}>
            <Button
              onClick={() => setReportBack('')}
              style={{alignSelf: 'flex-start'}}>
              <ArrowBackIosIcon />
            </Button>
            <div
              className={classes.navbarComponentHeadingEmpty}
              style={{padding: 4}}>
              {strings.ModuleSpecificUtilitie.expenseReportPage.Expense_Status_Report}
            </div>
          </div>
          <div className={classes.expenseReportsNavStyleToggle}>
            <ActiveInActiveToggle
              status={isActive}
              activeInactive={toggleHandler}
            />
          </div>
        </div>,
      ),
    );
    return () => {
      dispatch(
        routeStateActions.setNavbarComponentHeading(
          <div className={classes.navbarComponentHeadingEmpty}>
            {strings.ModuleSpecificUtilitie.expenseReportPage.expenseReport}
          </div>,
        ),
      );
    };
  }, [isActive]);
  return (
    <Container maxWidth={false} style={{width: '100%'}}>
      <ExpenseStatusReportHeader setReport={setReport} />
      {report && <ExpenseStatusReport />}
    </Container>
  );
};

export default ExpenseStatusReportContent;

import {createAction, createSlice} from '@reduxjs/toolkit';

export const drcTransferPositionInitialState = {
  employeeList: {},
  loading: false,
  positionTransferRequestList: [],
  positionTransferUserDetails: [],
  positionTransferStatus: false,
  searchText: '',
  gridOptions: {
    pageNo: 0,
    pageSize: 10,
  },
  transferToPrevPositionResponse: null,
  originalPositionRevertResponse: null,
};

export const fetchEmployeeBySAPCodeCreator = createAction<any>(
  'DCR_TRANSFER_PROMOTION_HANDLER/EMPLOYEE_BY_SAPCODE',
);
export const fetchEmployeeBySAPCodeTypeName =
  fetchEmployeeBySAPCodeCreator(null).type;

export const fetchPositionTransferRequestListCreator = createAction(
  'DCR_TRANSFER_PROMOTION_HANDLER/FETCH_POSITION_TRANSFER_REQUEST_LIST',
);
export const fetchPositionTransferRequestListCreatorTypeName =
  fetchPositionTransferRequestListCreator().type;

export const fetchPositionTransferUserDetailsCreator = createAction<any>(
  'DCR_TRANSFER_PROMOTION_HANDLER/FETCH_POSITION_TRANSFER_USER_DETAILS',
);
export const fetchPositionTransferUserDetailsTypeName =
  fetchPositionTransferUserDetailsCreator(null).type;

export const transferToPrevPositionCreator = createAction<any>(
  'DCR_TRANSFER_PROMOTION_HANDLER/TRANSFER_TO_PREV_POSITION',
);
export const transferToPrevPositionTypeName = transferToPrevPositionCreator(
  {},
).type;
export const revertToOriginalPositionCreator = createAction<any>(
  'DCR_TRANSFER_PROMOTION_HANDLER/REVERT_TO_ORIGINAL_POSITION',
);
export const revertToOriginalPositionTypeName = revertToOriginalPositionCreator(
  {},
).type;

const dcrTransferPromotionStateHandler = createSlice({
  name: 'DCR_TRANSFER_PROMOTION_CONFIG',
  initialState: drcTransferPositionInitialState,
  reducers: {
    setEmployeeList: (prevState, action) => {
      return {
        ...prevState,
        employeeList: action.payload,
      };
    },
    setPositionTransferRequestList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        positionTransferRequestList: payload,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setPositionTransferUserDetails: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        positionTransferUserDetails: payload,
      };
    },
    setPositionTransferStatus: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        positionTransferStatus: payload,
      };
    },
    resetPositionTransferInitialState: () => {
      return {
        ...drcTransferPositionInitialState,
      };
    },
    setPositionTransferRequestSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action.payload,
      };
    },
    setPositionTransferRequestPageSize: (prevState, action) => {
      return {
        ...prevState,
        searchText: action.payload,
      };
    },
    setPositionTransferRequestGridOptions: (prevState, action) => {
      return {
        ...prevState,
        gridOptions: action.payload,
      };
    },
    setPreviousPositionTransferResponse: (prevState, action) => {
      return {
        ...prevState,
        transferToPrevPositionResponse: action.payload,
      };
    },
    setOriginalPositionRevertResponse: (prevState, action) => {
      return {
        ...prevState,
        originalPositionRevertResponse: action.payload,
      };
    },
  },
});

export const dcrTransferPromotionStateActions =
  dcrTransferPromotionStateHandler.actions;
export const dcrTransferPromotionStateReducer =
  dcrTransferPromotionStateHandler.reducer;

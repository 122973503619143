import {createAction, createSlice} from '@reduxjs/toolkit';

export const expenseReportsInitialState = {
  reportData: null,
  TALoading: false,
  filtersTA: {},
  selectedStaff: [],
  expenseReportsPage: [],
  BifurcationAutoSuggestStaffDetails: [],
  BifurcationAutoSuggestLoading: false,
  BifurcationAction: [],
  filtersBI: {},
  reportDataYearly: [],
  yearlyLoading: false,
  reportDataBI: [],
  bifurcateLoading: false,
  bifurcationMonth: [],
  isActiveTaSummary: true,
  isActiveBifurcation: true,
  isActiveYearly: true,
  statusReportData:null,
};

export const fetchExpenseReportsPagesCreator = createAction(
  'EXPENSE_REPORT/REPORT_PAGES',
);

export const fetchExpenseReportsPagesCreatorTypeName =
  fetchExpenseReportsPagesCreator().type;

export const fetchExpenseReportCreator = createAction<any>(
  'EXPENSE_REPORT/REPORT_DATA',
);

export const fetchExpenseReportCreatorTypeName = fetchExpenseReportCreator(
  {},
).type;

export const fetchExpenseStatusReportCreator = createAction<any>(
  'EXPENSE_REPORT/STATUS_REPORT_DATA',
);

export const fetchExpenseStatusReportCreatorTypeName = fetchExpenseStatusReportCreator(
  {},
).type;

//Yearly report
export const fetchExpenseReportYearlyCreator = createAction<any>(
  'EXPENSE_REPORT/REPORT_DATA_YEARLY',
);

export const fetchExpenseReportCreatorYearlyTypeName =
  fetchExpenseReportYearlyCreator({}).type;

//Bifurcation report
export const fetchExpenseReportBICreator = createAction<any>(
  'EXPENSE_REPORT/REPORT_DATA_BIFURCATE',
);

export const fetchExpenseReportCreatorBITypeName = fetchExpenseReportBICreator(
  {},
).type;

// expense Bifurcation

export const fetchBifurcationAutoSuggestStaffCreator = createAction<any>(
  'BIFURCATION_HANDLER/FETCH_AUTO_SUGGEST_STAFF',
);
export const fetchBifurcationAutoSuggestStaffCreatorTypeName =
  fetchBifurcationAutoSuggestStaffCreator({}).type;

export const fetchBifurcationExpenseReportCreator = createAction<any>(
  'Bifurcation_EXPENSE_REPORT/REPORT_DATA',
);

export const fetchBifurcationExpenseReportCreatorTypeName =
  fetchBifurcationExpenseReportCreator({}).type;

const expenseReportsHandler = createSlice({
  name: 'EXPENSE_REPORTS',
  initialState: expenseReportsInitialState,
  reducers: {
    setReportData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        reportData: payload,
      };
    },
    setStatusReportData: (prevState, action) => {
      
      const {payload} = action;
      
      return {
        ...prevState,
        statusReportData: payload,
      };
    },
    setTALoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        TALoading: payload,
      };
    },
    setBIData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        reportDataBI: payload,
      };
    },
    setBifurLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        bifurcateLoading: payload,
      };
    },
    setFiltersTA: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filtersTA: {
          ...prevState.filtersTA,
          ...payload,
        },
      };
    },
    setSelectedStaffData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedStaff: payload,
      };
    },
    resetFiltersTA: prevState => {
      return {
        ...prevState,
        filtersTA: {},
      };
    },
    setExpenseReportsPage: (prevState, action) => {
      return {
        ...prevState,
        expenseReportsPage: action.payload,
      };
    },
    setFiltersBI: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        filtersBI: {
          ...prevState.filtersBI,
          ...payload,
        },
      };
    },
    setBifurcationAutoSuggestStaffDetail: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        BifurcationAutoSuggestStaffDetails: payload,
      };
    },
    setBifurcationAutoSuggestLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        BifurcationAutoSuggestLoading: payload,
      };
    },
    setBifurcationAction: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        statusManagerAction: payload,
      };
    },
    resetFiltersBI: prevState => {
      return {
        ...prevState,
        filtersBI: {},
      };
    },
    setYearlyReport: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        reportDataYearly: payload,
      };
    },
    setYearlyLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        yearlyLoading: payload,
      };
    },
    setBifurcationMonth: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        bifurcationMonth: payload,
      };
    },
    setIsActiveTaSummary: (prevState, action) => {
      const {payload} = action;
      return {...prevState, isActiveTaSummary: payload};
    },
    setIsActiveBifurcation: (prevState, action) => {
      const {payload} = action;
      return {...prevState, isActiveBifurcation: payload};
    },
    setIsActiveYearly: (prevState, action) => {
      const {payload} = action;
      return {...prevState, isActiveYearly: payload};
    },
  },
});
export const expenseReportsActions = expenseReportsHandler.actions;
export const expenseReportsReducer = expenseReportsHandler.reducer;

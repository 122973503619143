import React, {useRef, useState, useEffect} from 'react';
import './styles.css';

type SegmentedControlProps = {
  name: string;
  segments: any[];
  callback: (value?: any, index?: number) => void;
  defaultIndex?: number;
  controlRef: any;
  containerStyle?: any;
  itemStyle?: any;
};

export const InventoryToogle: React.FC<SegmentedControlProps> = ({
  name,
  segments,
  callback,
  defaultIndex = 0,
  controlRef,
  containerStyle = {},
  itemStyle = {},
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const componentReady = useRef();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    componentReady.current = true;
  }, []);
  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].ref;
    const {offsetWidth, offsetLeft} = activeSegmentRef.current;
    const {style} = controlRef.current;

    style.setProperty('--highlight-width', `${offsetWidth + 12}px`);
    style.setProperty('--highlight-x-pos', `${offsetLeft - 1}px`);
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value: any, index: number) => {
    setActiveIndex(index);
    callback(value, index);
  };

  return (
    <div className="controls-container" ref={controlRef} style={containerStyle}>
      <div
        className={`controls ${componentReady.current ? 'ready' : 'idle'}`}
        style={{width: '100%'}}>
        {segments?.map((item, i) => (
          <div
            key={item.value}
            className={`segment ${i === activeIndex ? 'active' : 'inactive'}`}
            style={itemStyle}
            ref={item.ref}>
            <input
              data-testid={'toogle-switch-button-' + i}
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <label htmlFor={item.label}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

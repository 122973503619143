import {call, takeLatest, put, all} from '@redux-saga/core/effects';
import {
  dcrTransferPromotionStateActions,
  fetchEmployeeBySAPCodeTypeName,
  fetchPositionTransferRequestListCreatorTypeName,
  fetchPositionTransferUserDetailsTypeName,
  transferToPrevPositionTypeName,
  revertToOriginalPositionTypeName,
} from './slice';
import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {select} from 'redux-saga/effects';
import {dcrTransferPromotionSelector} from './selector';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {appSliceActions} from '@app/store/reducers/appSlice';

import {fullNameConstructor} from '../utils';

/*
    Get Employees by SAPCODE watcher
*/
export function* fetchEmpBySAPCodeWatcher() {
  yield takeLatest(fetchEmployeeBySAPCodeTypeName, fetchEmpBySAPCodeWorker);
}

/* Get Position Transfer Requests List watcher*/
export function* fetchPositionTransferRequestListWatcher() {
  yield takeLatest(
    fetchPositionTransferRequestListCreatorTypeName,
    fetchPositionTransferRequestListWorker,
  );
}

/*Get the details of the user, to transfer his position: BY SAPCODE, WATCHER*/
export function* fetchPositionTransferUserDetailsBySapCodeWatcher() {
  yield takeLatest(
    fetchPositionTransferUserDetailsTypeName,
    fetchPositionTransferUserDetailsBySapCodeWorker,
  );
}

/*Transfer user to prev position watcher*/
export function* transferToPrevPositionWatcher() {
  yield takeLatest(
    transferToPrevPositionTypeName,
    transferToPrevPositionWorker,
  );
}
/* Transfer user to his Original position watcher*/
export function* transferToOriginalPositionWatcher() {
  yield takeLatest(
    revertToOriginalPositionTypeName,
    transferToOriginalPositionWorker,
  );
}
/*Workers*/

/*
    Get Employees by SAPCODE watcher
*/
export function* fetchEmpBySAPCodeWorker(action: any): any {
  try {
    const obj = yield select(dcrTransferPromotionSelector.getEmployeesList());
    const {payload, type} = action.payload;
    const url = `${API_PATH.positionManagement.positioncloningtransfer}`;

    const response = yield call(NetworkService.post, url, {...payload}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        dcrTransferPromotionStateActions.setEmployeeList({
          details:
            response?.data?.length === 1 ? response?.data?.[0] : response.data,
        }),
      );
    }
  } catch (error) {}
}

/* Get Position Transfer Requests List worker*/
export function* fetchPositionTransferRequestListWorker(action: any): any {
  /*get the grid options here*/
  const gridOptionsObj = yield select(
    dcrTransferPromotionSelector.getPositionTransferRequestGridOptionSelector(),
  );

  const pageNo = gridOptionsObj?.pageNo + 1 || 1;
  const pageSize = gridOptionsObj?.pageSize || 10;
  const searchText = yield select(
    dcrTransferPromotionSelector.getPositionTransferRequestSearchTextSelector(),
  );
  const obj = yield select(
    dcrTransferPromotionSelector.getPositionTransferStatusSelector(),
  );
  try {
    const url = `${API_PATH.moduleSpecificUtilities.positionTransfer.staffPositionRevertList}?pageSize=${pageSize}&pageNo=${pageNo}&searchText=${searchText}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        dcrTransferPromotionStateActions.setPositionTransferRequestList(
          response?.data,
        ),
      );
    }
    yield put(appSliceActions.setAppLoading(false));
    yield put(
      dcrTransferPromotionStateActions.setPositionTransferStatus(false),
    );
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching transfer details!',
      ToastType.ERROR,
    );
  }
}

/*Get position details of users by SAP CODE*/
export function* fetchPositionTransferUserDetailsBySapCodeWorker(
  actions: any,
): any {
  const sapCode = actions.payload.sapCode;
  try {
    const url = `${API_PATH.moduleSpecificUtilities.positionTransfer.staffpositionrevertdetailsbysapcode}?SAPCode=${sapCode}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        dcrTransferPromotionStateActions.setPositionTransferUserDetails(
          response?.data,
        ),
      );
    }
    //Need to handle the case for no data too,
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching transfer details!',
      ToastType.ERROR,
    );
  }
}

/*Transfer position to previous role worker*/
export function* transferToPrevPositionWorker(actions: any): any {
  const {payload} = actions;
  try {
    const url =
      API_PATH.moduleSpecificUtilities.positionTransfer
        .staffPositionTransferToPreviousPosition;
    // start the loader
    yield put(dcrTransferPromotionStateActions.setPositionTransferStatus(true));

    // get the response
    const response = yield call(
      NetworkService.post,
      url,
      payload?.userDetailsForTransferAction,
    );
    if (response?.status === HTTP_OK) {
      yield put(
        dcrTransferPromotionStateActions.setPreviousPositionTransferResponse({
          status: response?.data,
          empName: payload?.empFullName,
        }),
      );
    }
  } catch (error) {
    yield put(
      dcrTransferPromotionStateActions.setPreviousPositionTransferResponse(
        null,
      ),
    );
  }
}
/*Transfer position to original role watcher*/

export function* transferToOriginalPositionWorker(actions: any): any {
  const {payload} = actions;

  try {
    const url =
      API_PATH.moduleSpecificUtilities.positionTransfer
        .staffPositionRevertToOriginalPosition;
    const response = yield call(
      NetworkService.post,
      url,
      payload?.apiRequestPayload,
    );
    if (response?.status === HTTP_OK) {
      yield put(
        dcrTransferPromotionStateActions.setOriginalPositionRevertResponse({
          status: response?.data,
          empName: payload?.empName,
        }),
      );
    }
  } catch (error) {
    yield put(
      dcrTransferPromotionStateActions.setOriginalPositionRevertResponse(null),
    );
  }
}

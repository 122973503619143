import {Button, Container, Grid} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useStyles} from '@app/screens/Styles/style';
import TASummaryReportContent from '@app/screens/module-specific-utilities/components/expense-reports/TA-summary/TASummaryReportContent';
import {fetchExpenseReportsPagesCreator} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/slice';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import BifurcationHeader from '@app/screens/module-specific-utilities/components/expense-reports/bifurcationReport/BifurcationHeader';
import YearlySummaryContent from '@app/screens/module-specific-utilities/components/expense-reports/yearly-disbursement-summary/yearlySummaryContent';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import Colors from '@app/themes/colors';
import { strings } from '@app/common/strings';
import ExpenseStatusReportContent from '../../components/expense-reports/expense-status-report/ExpenseStatusReportContent';
const styles = {
  reportNames:{
              padding: 20,
                margin: 10,
                backgroundColor:Colors?.bgcolor,
  },
  containerWidth:{
    width: '80%'
  }
}
export const ExpenseReports = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reportPages = useSelector(
    expenseReportsStateSelector.getExpenseReportsPage(),
  );
  const [report, setReport] = useState('');
  const handleClick = (reportName: string) => {
    setReport(reportName);
  };
  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.commonheader,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeadingEmpty}>
         {strings.ModuleSpecificUtilitie.expenseReportPage.expenseReport}
        </div>,
      ),
    );
    dispatch(fetchExpenseReportsPagesCreator());
    dispatch(approveExpenseStateActions?.setStaffData([]));
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, []);
  if (report === strings.ModuleSpecificUtilitie.expenseReportPage.TA_Summary) {
    dispatch(approveExpenseStateActions?.setStaffData([]));
    return <TASummaryReportContent setReportBack={setReport} />;
  } else if (report === strings.ModuleSpecificUtilitie.expenseReportPage.Yearly_Disbursement_Summary) {
    dispatch(approveExpenseStateActions?.setStaffData([]));
    return <YearlySummaryContent setReportBack={setReport} />;
  } else if (report === strings.ModuleSpecificUtilitie.expenseReportPage.Expense_Bifurcation) {
    dispatch(approveExpenseStateActions?.setStaffData([]));
    return <BifurcationHeader setReportBack={setReport} />;
  } else if (report === strings.ModuleSpecificUtilitie.expenseReportPage.Expense_Status_Report) {
    dispatch(approveExpenseStateActions?.setStaffData([]));
    return <ExpenseStatusReportContent setReportBack={setReport} />;
  }
  
  return (
    <Container maxWidth={false} style={styles.containerWidth}>
      <Grid container spacing={0.5} direction="row">
        {reportPages.map((obj: any) => (
          <>
          <Grid
            data-testid={`pages-${obj.id}`}
            key={obj.id}
            item
            xs={5.6}
            component={Button}
            style={styles.reportNames}
            onClick={() => handleClick(obj.reportName)}>
            <div>{obj.reportName}</div>
          </Grid>
          </>
        ))}
      </Grid>
    </Container>
  );
};
